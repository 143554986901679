import React, { FC } from 'react';
import Slider from 'react-slick';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BlogPost } from '../../models/blog-post';
import './blog-home.css';

const BlogHome = () => {
  const data = useStaticQuery(graphql`
    query {
      desktopImage: file(relativePath: { eq: "hero-images/fake-ID-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      servingAlcohol: file(relativePath: { eq: "blog/serving-alcohol.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mcLovingFakeIDs: file(relativePath: { eq: "blog/fake-id-post.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      facialRecHero: file(relativePath: { eq: "blog/facial-recognition.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sspotFakeIDs: file(relativePath: { eq: "blog/7-spot-fake-ids.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      allFile(filter: { relativeDirectory: { eq: "logos/home" } }) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  const blogHeroPosts: BlogPost[] = [
    {
      heading: 'How to Spot Fake IDs',
      description:
        'Read these 7 tips to spot fake IDs. Learn where counterfeiters typically make mistakes and how you can best train your team to catch fake IDs and prevent fraud.',
      miniDescription: '7 tips on catching fake IDs',
      image: data.sspotFakeIDs.childImageSharp.fluid,
      link: '/id-scanning-blog/how-to-spot-a-fake-id',
      CTA: 'See Tips',
    },
    {
      heading: 'How Accurate is Facial Recognition?',
      description:
        'Facial recognition has come a long way in the past few years. The technology is being touted as a quick means to match individuals against known databases. But just how accurate is it for verifying IDs?',
      miniDescription: 'Learn why this technology is controversial',
      image: data.facialRecHero.childImageSharp.fluid,
      link: '/id-scanning-blog/how-accurate-is-facial-recognition',
      CTA: 'Read More',
    },
    {
      heading: 'Do Fake IDs Pass ID Scanners?',
      description:
        'People often ask us how reliable are ID scanners in catching fake IDs. The answer might surprise you. Read more on how ID scanning technology, and fake IDs, have evolved over the years and what the modern counterfeiter is capable of.',
      miniDescription: 'This should really only be about 5 words',
      image: data.mcLovingFakeIDs.childImageSharp.fluid,
      link: '/id-scanning-blog/do-fake-ids-pass-scanners',
      CTA: 'Learn More',
    },
    {
      heading: 'Serve Alcohol Responsibly with Patronscan',
      description:
        'Patronscan is pleased to partner with Serving Alcohol to prevent the sale of alcohol to minors. Serving Alcohol provides certifications to sell alcohol responsibly and recommends using Patronscan ID scanners to catch fake IDs.',
      miniDescription: 'This should really only be about 5 words',
      image: data.servingAlcohol.childImageSharp.fluid,
      link: '/id-scanning-blog/serving-alcohol-partnership',
      CTA: 'Read More',
    },
  ];

  const HeroSliderSettings = {
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 6500,
    speed: 1200,
    initialSlide: 0,
    centerPadding: '25px',
    // arrows: true,
    infinite: true,
    // dots: true,
  };
  return (
    <div className="blog-container">
      <div className="">
        <Slider {...HeroSliderSettings}>
          {blogHeroPosts.map((blogPostSing: BlogPost) => (
            <div className="blog-hero">
              <div className="flex flex-col lap:flex-row p-2 tab:p-4 bg-grey-lightest">
                <div className="blog-hero-image">
                  <a href={blogPostSing.link}>
                    <Img fluid={blogPostSing.image} />
                  </a>
                </div>
                <div className="blog-hero-text px-0 lap:px-3 desk:px-5">
                  <div className="h-9/10">
                    <div className="font-heading pt-4 py-2 lap:pr-24 text-mh lap:text-mhd pr-10">
                      {blogPostSing.heading}
                    </div>
                    <div className="text-mb py-2 mt-8 lap:mt-0">{blogPostSing.description}</div>
                    <div className="mt-3 py-2 text-blue-darkest text-sb">
                      <a href={blogPostSing.link}>{blogPostSing.CTA}</a>
                    </div>
                  </div>
                  <div className="mt-8 lap:mt-0 h-1/10 arrows-container">{/* Arrows */}</div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="flex flex-wrap mt-10 tab:mt-12">
        {blogHeroPosts.map((blogPostSing: BlogPost) => (
          <div className="blog-card bg-grey-lightest p-4">
            <a href={blogPostSing.link}>
              <div className="blog-card-image">
                <Img fluid={blogPostSing.image} />
              </div>
              <div className="font-heading text-hs mb-2 mt-4">{blogPostSing.heading}</div>
              <div className="text-sb">{blogPostSing.miniDescription}</div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogHome;
