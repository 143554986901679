import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BlogHome from '../components/sections/blog-home';
import SEO from '../components/seo';
import './id-scanning-blog.css';

const IDScanningBlog = () => {
  const data = useStaticQuery(graphql`
    query {
      callUs: file(relativePath: { eq: "icons/call_us.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className="mx-sm mb-sm mt-top lap:mx-md mb-md mt-toplg desk:mx-lg mb-lg mt-toplg flex justify-center">
        <BlogHome />
    </div>
  );
};

export default IDScanningBlog;
 